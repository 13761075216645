@import "../scss/index.scss";

header {
  background-color: $white-color;
  .sub_header {
    background-color: $main-color !important;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    @include break-point(mobile) {
      display: none;
    }
    @include break-point(mobilestart) {
      align-items: baseline;
    }
    .sub_contact {
      max-width: 80%;
      width: 100%;
      a {
        color: $white-color;
        margin-right: 10px;
        font-size: 14px;
        img {
          width: 20px;
          margin-right: 5px;
        }
      }
    }
    .socila_links {
      max-width: calc(100% - 80%);
      width: 100%;
      text-align: right;

      a {
        margin-right: 12px;
        @include break-point(mobilestart) {
          margin-right: 6px;
        }
      }
      span {
        color: $white-color;
        margin-right: 10px;
      }
    }
  }
  .wrapper_header {
    .bg-body-tertiary {
      background-color: transparent !important;
      .navbar-brand {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        @include break-point(tablet) {
          width: unset;
        }
        .desktop_header {
          display: flex;
          align-items: center;
          .navbar-nav {
            .nav-link {
              font-size: 14px;
              font-weight: 600;
              color: $black-color;
              margin-right: 15px;
              position: relative;
              transition: all 0.4s ease-in-out;
              &.active {
                &::before {
                  content: "";
                  position: absolute;
                  height: 2px;
                  background-color: #f57c00;
                  width: 100%;
                  bottom: 0px;
                  left: 0;
                }
              }
            }
          }
          @include break-point(tablet) {
            display: none;
          }
          .nav-item {
            .dropdown-menu {
              width: 300px;
              .dropdown-item {
                white-space: break-spaces;
                margin-right: 0;
                font-size: 14px;
                font-weight: 500;
                &:hover {
                  background-color: $btn-brown-bg;
                  color: $white-color;
                }
              }
            }
          }
        }
        .logo_icon {
          max-width: 90px;
          width: 100%;
        }
      }
    }
  }
}
.offcanvas {
  // width: 80% !important;
  .offcanvas-header{
    .offcanvas-title{
      img{
        max-width: 100px;
        width: 100%;
      }
    }
  }
  .offcanvas-body {
    .navbar-nav {
      a {
        color: $black-color;
        margin-right: 15px;
        font-size: 15px;
        font-weight: 600;
        @include break-point(lscapeTablet) {
          margin-right: 5px;
        }
      }
      .nav-item {
        .dropdown-menu {
          width: 300px;
          .dropdown-item {
            white-space: unset;
            margin-right: 0;
            &:hover {
              background-color: $btn-brown-bg;
              color: $white-color;
            }
            // &:active {
            //   background-color: $white-color;
            // }
          }
        }
      }
    }
  }
}
