$main-color: #0a102f;
$white-color: #fffbfa;
$black-color: #39393a;
$black-opacity: rgba(57, 57, 58, 0.4);
$span-opacity: rgba(57, 57, 58, 0.8);
$black-opacity2: rgba(57, 57, 58, 0.1);
$btn-brown-bg: #d56241;
$border-color-img: #39393a;
$border-input:rgba(10, 16, 47, 0.3);
$border-footer:rgba(255, 255, 255, 0.1);
$text-footer:rgba(255, 255, 255, 0.8);
