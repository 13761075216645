@import "./index.scss";

.wrapper_landing {
  .dashbaord_content {
    .wrapper_welcome {
      .wrap_img_section {
        position: relative;
        figure {
          &::before {
            content: "";
            position: absolute;
            background: linear-gradient(
              89.97deg,
              #181818b4 46.32%,
              rgba(57, 57, 58, 0) 99.97%
            );
            width: 75%;
            height: 100%;
          }
          @include break-point(mobile) {
            height: 40vh;
          }
          img {
            @include break-point(mobile) {
              height: 100%;
            }
          }
        }
        .heading_set {
          position: absolute;
          top: 36%;
          @include break-point(mobile) {
            top: 20%;
          }
          h1 {
            color: $white-color;
            font-size: 54px;
            @include break-point(mobile) {
              font-size: 36px;
            }
            @include break-point(mobile) {
              font-size: 36px;
            }
          }
          p {
            color: $white-color;
            font-weight: 500;
            font-size: 22px;
            @include break-point(mobile) {
              font-size: 16px;
            }
            @include break-point(mobile) {
              font-size: 16px;
            }
          }
        }
      }
    }
    .wrapper_about_section {
      padding: 40px 0px;
      @include break-point(miniTabs) {
        padding: 20px 0px;
      }
      .about_content {
        display: flex;
        align-items: center;

        .row {
          @include break-point(miniTabs) {
            flex-direction: column-reverse;
          }

          .txt_content {
            padding-right: 30px;
            @include break-point(miniTabs) {
              padding-top: 20px;
              padding-right: 0px;
            }
            @include break-point(mobile) {
              padding-right: 0px;
            }
            .nav_btn_cstm_more {
              margin-top: 20px;
            }
            ul {
              list-style-type: disc;
              padding-left: 20px;

              li {
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          .img_content {
            display: flex;
            justify-content: right;
            @include break-point(miniTabs) {
              justify-content: center;
            }
            figure {
              max-width: 450px;
              // margin: 0 auto;
              position: relative;
              @include break-point(mobile) {
                max-width: 300px;
              }
              &:before {
                content: "";
                position: absolute;
                border: 2px solid $black-color;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 10px;
                border-top-left-radius: 4px;
                width: 100%;
                height: 100%;
                top: 20px;
                right: 20px;
              }
              img {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 10px;
                border-top-left-radius: 4px;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .wrapper_why_us {
      padding: 40px 0px;
      @include break-point(miniTabs) {
        padding: 20px 0px;
      }
      .about_content {
        display: flex;
        align-items: center;
        .row {
          .img_content {
            display: flex;
            justify-content: left;
            @include break-point(miniTabs) {
              justify-content: center;
            }
            figure {
              max-width: 450px;
              position: relative;
              @include break-point(mobile) {
                max-width: 300px;
              }
              &:before {
                content: "";
                position: absolute;
                border: 2px solid $black-color;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 10px;
                border-top-left-radius: 4px;
                width: 100%;
                height: 100%;
                top: 20px;
                right: 0px;
                @include break-point(mobile) {
                  right: 20px;
                }
              }
              img {
                margin-left: 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 10px;
                border-top-left-radius: 4px;
                @include break-point(mobile) {
                  margin-left: 0px;
                }
              }
            }
          }
          .txt_content {
            padding-left: 50px;
            @include break-point(miniTabs) {
              padding-top: 20px;
              padding-left: 0px;
            }
            @include break-point(mobile) {
              padding-left: 0px;
            }

            .nav_btn_cstm_more {
              margin-top: 20px;
            }
            ul {
              list-style-type: disc;
              padding-left: 20px;
              li {
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .wrapper_programe {
      // background-color: rgba(213, 98, 65, 0.1);
      background-color: $white-color;

      padding: 40px 0px 60px 0px;

      @include break-point(miniTabs) {
        padding: 20px 0px 20px 0px;
      }
      .programe_content {
        .headings {
          text-align: center;
          h2 {
            margin-bottom: 15px;
          }
          p {
            max-width: 600px;
            width: 100%;
            margin: 0 auto;
          }
        }
        .row {
          padding-top: 20px;
          .wrap_card {
            border: 1px solid $black-opacity;
            border-radius: 8px;
            overflow: hidden;
            @include transition;
            @include break-point(miniTabs) {
              margin-bottom: 20px;
            }
            a {
              width: 100%;
              figure {
                margin-bottom: 0;
                // min-height: 210px;
                // max-height: 310px;
                overflow: hidden;
                img {
                  height: 100%;
                  transform: scale(1);
                  @include transition;
                }
              }
            }

            .inner_card_wrap {
              padding: 20px 20px 20px 20px;
              // background-color: #ffffffbd;
              @include break-point(miniTabs) {
                padding: 20px 15px 20px 15px;
              }
              p {
                margin-bottom: 0;
                font-weight: 600;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .calendar_cstm {
                padding: 10px 0px;
                svg {
                  font-size: 18px;
                  margin-right: 5px;
                  margin-bottom: 4px;
                }
                font-size: 14px;
                color: $black-color;
              }
              .sub_span {
                color: $black-color;
                opacity: 0.8;
                padding-bottom: 15px;
              }
              .nav_btn_cstm_more {
                border-radius: 8px;
                background-color: $main-color;
                border-color: $main-color;
                @include transition;
              }
            }
            &:hover {
              box-shadow: 0px 4px 32px 0px #00000040;
              .inner_card_wrap {
                .nav_btn_cstm_more {
                  background-color: $btn-brown-bg;
                  border-color: $btn-brown-bg;
                }
              }
              figure {
                img {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }
    .wrapper_leadership {
      // background-color: rgba(213, 98, 65, 0.1);
      background-color: $white-color;
      padding: 40px 0px 60px 0px;
      .programe_content {
        .headings {
          text-align: center;
          h2 {
            margin-bottom: 15px;
          }
          p {
            max-width: 600px;
            width: 100%;
            margin: 0 auto;
          }
        }
        .wrap_leader_image {
          padding-top: 20px;
          display: flex;
          @include break-point(mobile) {
            display: block;
          }
          .wrap_card {
            width: calc(100% / 3);
            padding: 10px;
            @include break-point(mobile) {
              width: 100%;
            }
            figure {
              margin-bottom: 0;
              // max-width: 320px;

              width: 100%;
              // height: 377px;I
              overflow: hidden;
              position: relative;
              // margin: 0 auto;
              @include break-point(mobile) {
                max-width: 320px;
                margin: 0 auto;
              }
              img {
                // height: 100%;
                object-fit: cover;
              }
            }
            .image_layer {
              position: absolute;
              bottom: 0;
              width: 100%;
              background-color: rgba(57, 57, 58, 0.8);
              padding: 10px 20px;
              p {
                color: $white-color;
                font-weight: 700;
                margin-bottom: 5px;
              }
              span {
                color: $btn-brown-bg;
              }
            }
          }
        }
      }
    }
    .wrapper_images_show {
      .images_wrapper {
        .images_custom_gallery {
          display: flex;
          align-items: center;
          figure {
            width: calc(100% / 3);
            margin-bottom: 0;
          }
        }
      }
    }
    .wrapper_latest_update {
      padding: 30px 0px 60px 0px;
      .programe_content {
        .headings {
          text-align: center;
          h2 {
            margin-bottom: 15px;
          }
          p {
            max-width: 600px;
            width: 100%;
            margin: 0 auto;
          }
        }
        .row {
          padding: 20px 100px 0px 100px;
          position: relative;
          @include break-point(mobile) {
            padding: 20px 20px 0px 20px;
          }
          .wrap_card {
            border: 1px solid $black-color;
            border-radius: 5px;
            padding: 15px;
            overflow: hidden;

            @include break-point(lscapeTablet) {
              padding: 10px;
            }
            figure {
              // min-height: 200px;
              // max-height: 310px;
              width: 100%;
              border-radius: 4px;
              overflow: hidden;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
              span {
                position: absolute;
                background-color: $white-color;
                padding: 5px;
                right: 10px;
                top: 10px;
                font-weight: 700;
                border-radius: 3px;
              }
            }
            .wrap_span {
              .sub_span {
                padding-bottom: 15px;
                svg {
                  color: $black-color;
                  font-size: 16px;
                  margin-right: 5px;
                  margin-bottom: 2px;
                }
                &:first-child {
                  padding-right: 10px;

                  @include break-point(lscapeTablet) {
                    padding-right: 5px;
                  }
                  @include break-point(tablet) {
                    padding-right: 15px;
                  }
                  @include break-point(mobile) {
                    padding-right: 5px;
                  }
                }
              }
            }
            p {
              font-weight: 700;
              color: $black-color;
            }
            a {
              color: $black-color;
              font-weight: 600;
              font-size: 14px;
              svg {
                transform: translateX(0);
                transition: all 0.3s ease-in-out;
              }
              &:hover {
                svg {
                  transform: translateX(75%);
                }
              }
            }
          }
          .slick-slider {
            position: unset;
            .slick-list {
              .slick-track {
                .slick-slide {
                  padding: 10px;
                }
              }
            }
            .slick-arrow {
              border-radius: 50%;
              background-color: $black-opacity2;
              border: 1px solid transparent;
              width: 50px;
              height: 50px;
              position: absolute;
              text-align: center;
              @include transition;
              @include break-point(mobile) {
                width: 30px;
                height: 30px;
              }
              &.slick-prev {
                left: 0;
              }
              &.slick-next {
                right: 0;
              }
              &::before {
                content: "";
                display: none;
              }
              svg {
                color: $black-color;
                font-size: 22px;
                height: 48px;
                @include transition;
                @include break-point(mobile) {
                  font-size: 14px;
                  height: 28px;
                }
              }
              &.slick-disabled {
                svg {
                  color: $white-color;
                }
                &:hover {
                  background-color: $black-opacity2;
                  svg {
                    color: $white-color;
                  }
                }
              }
              &:hover {
                background-color: $black-color;
                svg {
                  color: $white-color;
                }
              }
            }
          }
        }
      }
    }
    .wrapper_contact {
      background-color: $white-color;
      padding: 190px 0px 70px 0px;
      position: relative;
      @include break-point(mobile) {
        padding: 60px 0px 30px 0px;
      }
      .contact_us {
        position: relative;
        .wrapper_map {
          iframe {
            width: 100%;
          }
        }
        .wrapper_form {
          background-color: $white-color;
          max-width: 580px;
          width: 100%;
          padding: 20px;
          border: 1px solid $border-input;
          border-radius: 12px;
          position: absolute;
          top: -140px;
          @include break-point(mobile) {
            position: unset;
            margin: 0 auto;
            margin-top: 20px;
          }
          form {
            .wrap_form_field {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .form_field {
                margin-right: 5px;
                &:last-child {
                  margin-right: 0;
                }
              }
              @include break-point(mobile) {
                display: block;
                .form_field {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
      .wrap_set_varrow {
        max-width: 100px;
        width: 100%;
        margin-bottom: 0;
        position: absolute;
        top: -7%;
        right: 7%;
        img {
          width: 100%;
        }
      }
    }
    .wrapper_marque {
      padding: 60px 0px 40px 0px;
      .marquee {
        .marque_image {
          figure {
            // max-width: 200px;
            width: 100%;
            margin-bottom: 0;
            margin-right: 10px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    /*About section css*/
    .wrapper_about_heading {
      background-color: $main-color;
      .wrap_tittle {
        text-align: center;
        padding: 60px 0px 60px 0px;
        @include break-point(mobile) {
          padding: 40px 0px 40px 0px;
        }
      }
    }
    .About_us_page {
      .wrapper_why_us {
        background-color: $white-color;
      }
      .wrapper_leadership {
        background-color: unset;
      }
    }
    .download_brochure {
      background-color: $white-color;
      padding: 60px 0px 60px 0px;

      @include break-point(mobile) {
        padding: 40px 0px 40px 0px;
      }
      .wrap_brochure {
        text-align: center;
        .span_cnt {
          max-width: 600px;
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }
    .partners_gallery {
      padding: 40px 0px 40px 0px;
      .wrap_gallery {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        .wrap_partners_gallerys {
          width: calc(100% / 5);
          @include break-point(lgtablet) {
            width: calc(100% / 4);
          }
          @include break-point(mobile) {
            width: calc(100% / 3);
          }
          figure {
            max-width: 200px;
            margin: 0 auto;
            margin-bottom: 20px;
            width: 100%;
            img {
            }
          }
        }
      }
    }
    .wrapper_gallery {
      padding: 40px 0px 40px 0px;
      .wrap_gallery_img {
        .row {
          .wrap-img_card {
            figure {
              border-radius: 14px;
              overflow: hidden;
              // max-width: 500px;
              // width: 100%;
              // margin: 0 auto;
              img {
              }
            }
          }
        }
      }
    }
    .Contact_us_page {
      .contact_page_form {
        padding: 40px 0px 40px 0px;
        .wrapper_form {
          form {
            max-width: 720px;
            width: 100%;
            margin: 0 auto;
            .wrap_form_field {
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include break-point(mobile) {
                display: block;
              }
              .form_field {
                max-width: 49%;
                width: 100%;
                @include break-point(mobile) {
                  max-width: 100%;
                }
              }
            }
            .form_field {
              .input {
                background-color: $white-color;
              }
            }
            .text_right {
              text-align: right;
              .nav_btn_cstm_more {
                border-radius: 24px;
              }
            }
          }
        }
      }
      .wrapper_map {
        .map_view {
          height: 450px;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding-bottom: 0;
        border-bottom: 0;
        .modal-title {
          margin-bottom: 0;
          .h3_subheading {
            margin-bottom: 0;
          }
        }
        .btn-close {
          &:focus {
            box-shadow: unset;
          }
        }
      }
      .modal-body {
        form {
          max-width: 720px;
          width: 100%;
          margin: 0 auto;
          .wrap_form_field {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include break-point(mobile) {
              display: block;
            }
            .form_field {
              max-width: 49%;
              width: 100%;

              @include break-point(mobile) {
                max-width: 100%;
              }
            }
          }
          .form_field {
            .input {
              background-color: $white-color;
            }
          }
        }
      }
      .modal-footer {
        padding-top: 0;
        border-top: 0;
        max-width: 720px;
        margin: 0 auto;
        width: 100%;
        padding-right: 0;
        @include break-point(lgtablet) {
          padding-right: 16px;
        }
        .text_right {
          text-align: right;
          margin-right: 0;
          @include break-point(mobile) {
            width: 100%;
            text-align: center;
          }
          .nav_btn_cstm_more {
            border-radius: 24px;
          }
        }
      }
    }
  }
}
