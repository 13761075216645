@import "../scss/index.scss";

.footer {
  background-color: $main-color;
  position: relative;
  .wrap_footer {
    border-bottom: 1px solid $border-footer;
    padding: 50px 0px;
    @include break-point(mobile) {
      padding: 20px 0px;
    }
    .cstm_row {
      align-items: center;
      .headings {
        .h2_subheading {
          color: $white-color;
          .break_txt {
            display: block;
            @include break-point(miniTabs) {
              display: inline-flex;
            }
          }
        }
      }
      .footer_btn {
        text-align: center;
        .nav_btn_cstm {
          border-radius: 24px;
          padding: 10px 25px;
        }
      }
    }
  }
  .wrap_social_footer {
    padding: 60px 0px 60px 0px;
    @include break-point(mobile) {
      padding: 20px 0px 20px 0px;
    }
    .row {
      .wrap_footer_logo {
        @include break-point(mobile) {
          padding-bottom: 20px;
        }
        figure {
          max-width: 110px;
          width: 100%;
          margin-bottom: 25px;
          img {
            width: 100%;
          }
        }
        a {
          text-decoration: underline;
          padding-bottom: 20px;
          &:hover {
            color: $white-color;
          }
        }
        ul {
          display: flex;
          align-items: center;
          li {
            max-width: 40px;
            width: 100%;
            .cstm_anchor {
              width: 30px;
              height: 30px;
              background-color: $border-footer;
              border: 1px solid $border-footer;
              border-radius: 50%;
              overflow: hidden;
              line-height: 28px;
              text-align: center;

              img {
                width: unset;
              }
              &:hover {
                background-color: $btn-brown-bg;
              }
            }
          }
        }
      }
      .wrapper_quick_links {
        ul {
          li {
            a {
              display: flex;
              align-items: start;
              &:hover {
                color: $white-color;
                .sub_span_footer {
                  color: $white-color;
                }
              }
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                display: block;
                @include transition;
              }
            }
          }
        }
      }
    }
  }
  .sub_footer {
    background-color: $border-footer;
    padding: 10px 0px;
    text-align: center;
    span {
      padding-bottom: 0;
    }
  }
  .arrow_set {
    max-width: 100px;
    width: 100%;
    margin-bottom: 0;
    position: absolute;
    right: 4%;
    top: 0;
    transform: translateX(-4%);

    @include break-point(lscapeTablet) {
      right: 2%;
      transform: translateX(-2%);
    }
    @include break-point(lgtablet) {
      right: 0%;
      transform: translateX(0%);
    }
    @include break-point(mobile) {
      max-width: 70px;
    }
    img {
      width: 100%;
    }
  }
}
