@import "./index.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: $main-color;
}

a {
  display: inline-block;
  @include transition;
  text-decoration: none;
}

span {
  display: inline-block;
  font-size: 12px;
}

p {
  margin-bottom: 15px;
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
img {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 5px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 26px;
}

.nav_btn_cstm {
  background-color: $btn-brown-bg;
  border: 1px solid $btn-brown-bg;
  padding: 5px 15px;
  color: $white-color;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
}
.nav_btn_cstm_more {
  background-color: $btn-brown-bg;
  border: 1px solid $btn-brown-bg;
  padding: 7px 15px;
  color: $white-color;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
}
.Custom_container {
  max-width: 1240px;
  width: 100%;
  padding: 0px 10px;
  margin: 0 auto;
  @include break-point(xllgdesktop) {
    max-width: 1680px;
  }
  @include break-point(smlgdesktop) {
    max-width: 1440px;
  }
  @include break-point(mediumdesktop) {
    max-width: 1240px;
  }
  @include break-point(largeTab) {
    max-width: 1080px;
  }
  @include break-point(lscapeTablet) {
    max-width: 1040px;
  }
  @include break-point(lgtablet) {
    padding: 0px 20px;
  }
}

.h2_subheading {
  color: $main-color;
  font-size: 42px;
  margin-bottom: 15px;

  @include break-point(lgtablet) {
    font-size: 36px;
    margin-bottom: 10px;
  }
  @include break-point(mobile) {
    font-size: 28px;
  }
}
.h3_subheading {
  color: $main-color;
  font-size: 32px;
  margin-bottom: 15px;
  @include break-point(lgtablet) {
    font-size: 28px;
    margin-bottom: 10px;
  }
  @include break-point(mobile) {
    font-size: 24px;
  }
}

.span_cnt {
  font-size: 14px;
}

.sub_span {
  color: $span-opacity;
  padding-bottom: 15px;
  font-weight: 600;
}
.sub_span_footer {
  color: $text-footer;
  padding-bottom: 15px;
  font-size: 14px;
}
.sub_p_footer {
  color: $white-color;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}
.tittle_heading_h1 {
  font-size: 54px;
  color: $white-color;
  @include break-point(lgtablet) {
    font-size: 42px;
  }
  @include break-point(mobile) {
    font-size: 36px;
  }
}
.tittle_sub_heading_p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  color: $white-color;
  @include break-point(lgtablet) {
    font-size: 16px;
  }
  @include break-point(mobile) {
    font-size: 14px;
  }
}

form {
  .form_field {
    margin-bottom: 15px;
    .label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .input {
      border-radius: 24px;
      border: 1px solid $border-input;
      font-size: 14px;
      padding: 0.475rem 1.15rem;
      &::placeholder {
        font-size: 14px;
        color: $border-input;
      }
    }
    .nav_btn_cstm_more {
      font-size: 13px;
      padding: 7px 25px;
      border-radius: 6px;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.offcanvas {
  .offcanvas-body {
    padding-top: 0px;
  }
}
.error{
  font-size: 12px;
  margin-top: 5px;
}
